<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="170" viewBox="0 0 843.412 218.534" class="logo">
    <g id="Grupo_16" data-name="Grupo 16" transform="translate(-538.294 -430.733)">
      <path id="Trazado_42" data-name="Trazado 42" d="M692.7,469.624h0L705.4,454.483h0a101.716,101.716,0,0,0-166.71,86.784q.285,3.26.771,6.47a100.961,100.961,0,0,0,35.177,62.584l-12.706,15.141a100.964,100.964,0,0,0,67.742,23.775q3.243-.08,6.505-.365A101.716,101.716,0,0,0,692.7,469.624ZM674.954,587.517a62.19,62.19,0,0,1-87.615,7.665l12.706-15.141a62.189,62.189,0,0,1,79.948-95.28h0L667.288,499.9h0A62.26,62.26,0,0,1,674.954,587.517Z" fill="#ff4438"/>
      <g id="Grupo_15" data-name="Grupo 15">
        <g id="Grupo_13" data-name="Grupo 13">
          <path id="Trazado_43" data-name="Trazado 43" d="M1373.174,505.555a8.59,8.59,0,1,0,8.532,8.578A8.53,8.53,0,0,0,1373.174,505.555Zm0,15.483a6.9,6.9,0,1,1,6.649-6.905A6.708,6.708,0,0,1,1373.174,521.038Z" fill="#fff"/>
          <path id="Trazado_44" data-name="Trazado 44" d="M1374.745,514.987a2.708,2.708,0,0,0,1.8-2.569,2.825,2.825,0,0,0-2.992-2.772h-3.058v8.844h1.672v-3.3h.66l1.848,3.3h1.914l-1.978-3.451Zm-1.437-1.249h-1.144V511.1h1.144c.979,0,1.54.489,1.54,1.342C1374.848,513.265,1374.287,513.738,1373.308,513.738Z" fill="#fff"/>
        </g>
        <g id="Grupo_14" data-name="Grupo 14">
          <path id="Trazado_45" data-name="Trazado 45" d="M818.532,560.931a22.023,22.023,0,0,0-4.389-13.76c-2.927-3.813-7.785-7.336-14.438-10.469-7.966-3.646-12.424-5.808-13.625-6.61-4.281-2.679-6.456-5.674-6.456-8.893a7.565,7.565,0,0,1,3.542-6.51c2.306-1.584,5.532-2.387,9.587-2.387,6.472,0,13.956,2.493,22.253,7.413V506.494a47.815,47.815,0,0,0-22.723-5.844c-8.3,0-15.06,2.039-20.1,6.06a19.726,19.726,0,0,0-7.558,16.193,20.678,20.678,0,0,0,4.705,13.4c3.141,3.854,7.97,7.279,14.353,10.18,5.437,2.622,9.931,4.9,13.366,6.771,4.266,2.455,6.425,5.689,6.425,9.616a8.213,8.213,0,0,1-3.743,7.007,16.426,16.426,0,0,1-9.62,2.595c-7.631,0-15.847-3.536-24.429-10.513v14.628a58.281,58.281,0,0,0,12.459,5.819,46.511,46.511,0,0,0,13.438,1.718c7.943,0,14.495-2.155,19.475-6.405A21.081,21.081,0,0,0,818.532,560.931Z" fill="#fff"/>
          <path id="Trazado_46" data-name="Trazado 46" d="M883.176,570.5a29.25,29.25,0,0,0,3.624-14.742,27.989,27.989,0,0,0-3.768-14.444,25.822,25.822,0,0,0-10.663-9.908,33.438,33.438,0,0,0-15.4-3.543,33.036,33.036,0,0,0-15.229,3.514,26.156,26.156,0,0,0-10.663,9.852,27.717,27.717,0,0,0-3.825,14.529,29.244,29.244,0,0,0,3.623,14.742,25.61,25.61,0,0,0,10.575,9.964,33.015,33.015,0,0,0,15.519,3.66,33.419,33.419,0,0,0,15.549-3.661A25.5,25.5,0,0,0,883.176,570.5Zm-14.936-1.155c-2.765,3.206-6.6,4.832-11.393,4.832a13.649,13.649,0,0,1-11.075-5.045c-2.727-3.324-4.111-7.823-4.111-13.374,0-5.356,1.415-9.729,4.2-13a13.82,13.82,0,0,1,10.982-4.951c4.75,0,8.572,1.575,11.359,4.68s4.18,7.519,4.18,13.151C872.386,561.544,870.991,566.155,868.24,569.345Z" fill="#fff"/>
          <rect id="Rectángulo_7" data-name="Rectángulo 7" width="13.709" height="81.358" transform="translate(898.394 501.708)" fill="#fff"/>
          <path id="Trazado_47" data-name="Trazado 47" d="M979.627,570.5a29.257,29.257,0,0,0,3.624-14.742,27.984,27.984,0,0,0-3.768-14.444,25.818,25.818,0,0,0-10.664-9.908,33.431,33.431,0,0,0-15.4-3.543,33.029,33.029,0,0,0-15.228,3.514,26.153,26.153,0,0,0-10.664,9.852,27.718,27.718,0,0,0-3.826,14.529,29.244,29.244,0,0,0,3.624,14.742,25.606,25.606,0,0,0,10.576,9.964,33.008,33.008,0,0,0,15.518,3.66,33.422,33.422,0,0,0,15.549-3.661A25.506,25.506,0,0,0,979.627,570.5Zm-14.936-1.155c-2.766,3.206-6.6,4.832-11.393,4.832a13.647,13.647,0,0,1-11.075-5.045c-2.728-3.324-4.111-7.823-4.111-13.374,0-5.356,1.415-9.729,4.2-13a13.82,13.82,0,0,1,10.982-4.951c4.75,0,8.572,1.575,11.358,4.68s4.181,7.52,4.181,13.151C968.837,561.543,967.442,566.154,964.691,569.345Z" fill="#fff"/>
          <path id="Trazado_48" data-name="Trazado 48" d="M1001.536,507.052c-3.893,4.258-5.867,10.1-5.867,17.379v4.489h-7.817V539.1h7.817v43.963h13.709V539.1h15.6V528.92h-15.6v-4.312c0-4.127.655-6.69,2.263-8.849,3.145-4.223,11.19-3.317,14.6-2.492V502a40.7,40.7,0,0,0-9.147-1.351C1010.657,500.65,1005.421,502.8,1001.536,507.052Z" fill="#fff"/>
          <path id="Trazado_49" data-name="Trazado 49" d="M1086.82,570.5a29.249,29.249,0,0,0,3.624-14.742,27.989,27.989,0,0,0-3.768-14.444,25.816,25.816,0,0,0-10.663-9.908,33.438,33.438,0,0,0-15.4-3.543,33.029,33.029,0,0,0-15.228,3.514,26.146,26.146,0,0,0-10.664,9.852,27.716,27.716,0,0,0-3.825,14.529,29.243,29.243,0,0,0,3.623,14.742,25.61,25.61,0,0,0,10.575,9.964,33.015,33.015,0,0,0,15.519,3.66,33.419,33.419,0,0,0,15.549-3.661A25.5,25.5,0,0,0,1086.82,570.5Zm-14.936-1.155c-2.765,3.206-6.6,4.832-11.393,4.832a13.648,13.648,0,0,1-11.075-5.045c-2.728-3.323-4.111-7.823-4.111-13.374,0-5.356,1.415-9.729,4.2-13a13.82,13.82,0,0,1,10.982-4.951c4.75,0,8.572,1.575,11.359,4.68s4.18,7.519,4.18,13.151C1076.03,561.544,1074.635,566.155,1071.884,569.345Z" fill="#fff"/>
          <path id="Trazado_50" data-name="Trazado 50" d="M1154.617,570.5a29.247,29.247,0,0,0,3.624-14.742,27.984,27.984,0,0,0-3.768-14.444,25.822,25.822,0,0,0-10.663-9.908,33.439,33.439,0,0,0-15.4-3.543,33.029,33.029,0,0,0-15.228,3.514,26.153,26.153,0,0,0-10.664,9.852,27.721,27.721,0,0,0-3.825,14.529,29.243,29.243,0,0,0,3.623,14.742,25.607,25.607,0,0,0,10.576,9.964,33.008,33.008,0,0,0,15.518,3.66,33.427,33.427,0,0,0,15.55-3.661A25.5,25.5,0,0,0,1154.617,570.5Zm-14.936-1.155c-2.765,3.206-6.6,4.832-11.392,4.832a13.649,13.649,0,0,1-11.076-5.045c-2.727-3.324-4.111-7.823-4.111-13.374,0-5.356,1.415-9.729,4.2-13a13.82,13.82,0,0,1,10.983-4.951c4.75,0,8.571,1.575,11.358,4.68s4.18,7.52,4.18,13.151C1143.827,561.543,1142.432,566.154,1139.681,569.345Z" fill="#fff"/>
          <path id="Trazado_51" data-name="Trazado 51" d="M1193.793,527.863a27.669,27.669,0,0,0-13.716,3.567,26.143,26.143,0,0,0-9.968,9.822,27.4,27.4,0,0,0-3.623,13.918c0,8.592,2.621,15.461,7.79,20.418s12.174,7.478,20.811,7.478H1220.1V501.708h-13.65v28.6l-.5-.175A36.932,36.932,0,0,0,1193.793,527.863Zm12.659,12.5v32.4h-7.957c-11.909,0-17.948-6.079-17.948-18.067,0-4.974,1.488-9.055,4.423-12.131s6.917-4.642,11.821-4.642a20.548,20.548,0,0,1,9.458,2.334Z" fill="#fff"/>
          <path id="Trazado_52" data-name="Trazado 52" d="M1249.226,511.325a7.853,7.853,0,0,0-7.795-7.8,7.638,7.638,0,0,0-5.44,2.276,7.41,7.41,0,0,0-2.354,5.519,7.645,7.645,0,0,0,2.332,5.468,7.514,7.514,0,0,0,10.98,0A7.738,7.738,0,0,0,1249.226,511.325Z" fill="#fff"/>
          <rect id="Rectángulo_8" data-name="Rectángulo 8" width="13.708" height="54.146" transform="translate(1234.577 528.92)" fill="#fff"/>
          <path id="Trazado_53" data-name="Trazado 53" d="M1286.953,527.863c-8.007,0-14.589,2.631-19.566,7.819s-7.506,11.986-7.506,20.193c0,8.514,2.729,15.423,8.111,20.535s12.439,7.714,20.959,7.714c8.972,0,16.715-2.233,23.017-6.638v-9.839a43.33,43.33,0,0,1-9.4,5.066,28.689,28.689,0,0,1-10.093,1.816c-5.437,0-9.781-1.365-12.911-4.057s-5.026-6.583-5.623-11.554l-.05-.419h38.9v-1.8c0-8.99-2.34-16.123-6.955-21.2S1294.878,527.863,1286.953,527.863Zm-12.832,22.628.059-.428c.6-4.257,2.048-7.533,4.312-9.737a12.472,12.472,0,0,1,9.048-3.34,11.162,11.162,0,0,1,8.683,3.5c2.083,2.3,3.341,5.53,3.74,9.589l.04.412Z" fill="#fff"/>
          <path id="Trazado_54" data-name="Trazado 54" d="M1360.37,567.454a13.3,13.3,0,0,0-3.688-9.411c-2.488-2.641-6.892-5.571-13.092-8.709a54.24,54.24,0,0,1-8.124-4.573,3.736,3.736,0,0,1-1.6-2.756,4.162,4.162,0,0,1,1.931-3.662,9.112,9.112,0,0,1,5.086-1.239,26.718,26.718,0,0,1,8.526,1.637,41.444,41.444,0,0,1,8.319,3.936V532.011a42.624,42.624,0,0,0-8.182-3.131,33.873,33.873,0,0,0-8.428-1.017c-6.078,0-11.045,1.437-14.76,4.272a13.6,13.6,0,0,0-5.554,11.34,12.915,12.915,0,0,0,3.084,8.6c2.072,2.435,5.333,4.727,9.692,6.81,5.83,2.855,9.209,4.613,10.334,5.376a11.271,11.271,0,0,1,2.554,2.254,4.122,4.122,0,0,1,.9,2.582,4.317,4.317,0,0,1-2.479,3.822,12.333,12.333,0,0,1-6.183,1.372c-4.734,0-10.6-1.89-17.433-5.618v11.19a36.982,36.982,0,0,0,8.151,2.976,44.2,44.2,0,0,0,10.869,1.282c6.151,0,11.093-1.5,14.69-4.47C1358.557,576.708,1360.37,572.6,1360.37,567.454Z" fill="#fff"/>
        </g>
      </g>
    </g>
  </svg>

</template>
