<template>
    <footer class="block w-full p-normal border-t border-gray font-light bg-white text-gray">
      <div class="flex space-x-4">
        <div class="flex-1">
          <router-link to="/terms" class="hover:text-dgray visited:text-gray underline">
            <p>Condiciones de Uso y Contratación</p>
          </router-link>
        </div>
        <div class="flex-1">
          <router-link to="/cookies-politics" class="hover:text-dgray visited:text-gray underline">
            <p>Politica de Cookies</p>
          </router-link>
        </div>
        <div class="flex-1">
          <router-link to="/politics" class="hover:text-dgray visited:text-gray underline">
            <p>Políticas de privacidad</p>
          </router-link>
        </div>
      </div>
      <div class="flex space-x-4 mt-2">
        <div class="flex-1">SoloFoodies ©{{date}}</div>
      </div>
    </footer>
</template>

<script>

export default {
  name: 'Footer',
  data () {
    return {
      date: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>

</style>
