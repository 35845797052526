<template>
  <div class="homeCont">
    <div class="main_flex">
      <div class="headerContainer items-center px-4">
        <LogoWhite class="logo"></LogoWhite>
        <div class="flex items-center">
          <router-link to="/login" class="focus:outline-none login_btn py-2 w-full bg-primary text-white rounded-full">
            Iniciar sesión
          </router-link>
          <router-link to="/register" class="focus:outline-none login_btn py-2 ml-1 w-full bg-SFRed text-white rounded-full">
            Registro
          </router-link>
        </div>
      </div>
      <div class="contet_flex" ref="myRef1" :style="{background: `url(${require('@/assets/img/bg/bg-1.jpg')})`}">
        <div class="max-w-5xl">
          <div class="txt_apsolute _right">
            <div style="display: flex;gap: 10px;">
              <div>
                <i class="icon-colaborate ft-primary" style="font-size: 32px;"></i>
              </div>
              <div>
                <p class="tittle_welcome ft-primary">Restaurantes y foodies conectados como nunca</p>
                <p class="fs-18">Descubre una nueva dinámica de colaboraciones gastronómicas.</p>
              </div>
            </div>
          </div>
          <div class="img_cont">
            <img src="../../assets/img/rest-conectados.png" alt="@" />
          </div>
        </div>
      </div>
      <div class="contet_flex" ref="myRef2" :style="{background: `url(${require('@/assets/img/bg/bg-2.jpg')})`}">
        <div class="max-w-5xl">
          <div class="txt_apsolute">
            <div style="display: flex;gap: 10px;">
              <div>
                <i class="icon-coupon ft-primary" style="font-size: 32px;"></i>
              </div>
              <div>
                <p class="tittle_welcome ft-primary">Recomendaciones reales</p>
                <p class="fs-18">Gestiona colaboraciones con foodies apasionados y mide el impacto en tu negocio.</p>
              </div>
            </div>
          </div>
          <div class="img_cont">
            <img src="../../assets/img/explore-foodies.png" alt="@" />
          </div>
        </div>
      </div>
      <div class="contet_flex" ref="myRef3" :style="{background: `url(${require('@/assets/img/bg/bg-3.jpg')})`}">
        <div class="max-w-5xl">
          <div class="txt_apsolute _right">
            <div style="display: flex;gap: 10px;">
              <div>
                <i class="icon-thumbsUp ft-primary" style="font-size: 32px;"></i>
              </div>
              <div>
                <p class="tittle_welcome ft-primary">Los mejores foodies hablando de ti</p>
                <p class="fs-18">Una comunidad de creadores de contenido que despiertan el entusiasmo por descubrir la gastronomía.</p>
              </div>
            </div>
          </div>
          <div class="img_cont">
            <img src="../../assets/img/story_link.png" alt="@"/>
          </div>
        </div>
      </div>
    </div>
    <div class="contet_flex" ref="myRef4" :style="{background: `url(${require('@/assets/img/bg/bg-4.jpg')})`}">
      <div class="formContac">
        <Logo1 class="logo"></Logo1>
        <div class="mt-2 flex flex-col items-center space-y-4 pb-6 inset-x-0 overflow-hidden">
            <a href="/register/1" class="focus:outline-none py-3 w-full bg-primary text-white rounded-full">
              Soy un foodie increible
            </a>
            <a href="/register/2" class="focus:outline-none py-3 w-full bg-white border border-primary rounded-full text-primary">
              Soy un restaurante maravilloso
            </a>
          </div>
      </div>
      <Footer/>
    </div>
  </div>
</template>

<script>

import Logo1 from '@/components/logos/logo-1'
import LogoWhite from '@/components/logos/logo-white'
import Footer from '@/components/Footer'

export default {
  name: 'Home',
  components: {
    Logo1,
    Footer,
    LogoWhite
  },
  data: () => ({
    page: 1
  }),
  methods: {
    handleScroll (event) {
      /* if (this.page < 4) {
        this.page = this.page + 1
      } else {
        this.page = this.page - 1
      }
      console.log(this.page)
      this.$refs[`myRef${this.page}`].scrollIntoView({
        behavior: 'smooth'
      }) */
    }
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
    document.getElementById('theBody').classList.remove('pageableBody')
  },
  async mounted () {
    window.addEventListener('scroll', this.handleScroll)
    document.getElementById('theBody').classList.add('pageableBody')
  }
}
</script>

<style lang="scss" scoped>
  .headerContainer {
    position: absolute !important;
    z-index: 999; width: 100%;
    display: flex; top: 1%;
    justify-content: space-between;
  }
  .main_flex{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
    z-index: 1;
    min-height: 100vh;
  }
  .contet_flex{
    display:flex;
    align-items: center;
    align-content:center;
    position: relative;
    width: 100%;
    // border-bottom: 1px solid #f4f4f4;
    background-size: cover !important;
    background-position: 50% 50% !important;
    position:relative;
    min-height: 100vh;
    justify-content: center;
    @media screen and (min-width: 601px){
      padding: 40px;
      flex-direction: column;
    }
    @media screen and (max-width: 600px){
      flex-direction: column;
      padding: 10px;
    }
    &:last-of-type{
      border-bottom:none;
    }
    &:before{
      content: "";
      width: 100%;
      background: rgba(0, 0, 0, 0.4);
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 0;
    }
  }
  .logo{
    display:inline-block;
    &.logo_white{
      position:absolute;
      z-index: 2;
      @media screen and (min-width: 601px){
        top:2%;
        left:3%;
      }
      @media screen and (max-width: 600px){
        top: 20px;
        left: calc(50% - 150px);
        width: 180px;
      }
    }
  }

 .homeCont{
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    .contet_flex{
      scroll-snap-align: start;
    }
  }
  .bgSvg{
    position: absolute;
    // bottom: -25vh;
    left: auto;
    // transition: 1s .2s cubic-bezier(1,.12,.25,1);
    /* transition: 1.2s .3s cubic-bezier(1,.12,.25,1);
    transform: rotate(180deg);
    float: left; */
    z-index: -1;
    top: -100px;
    width: 99vw;
  }
  .tittle_welcome{
    font-size: 21px;
    font-weight: 500;
    text-transform: capitalize;
    text-align: left;
  }
  .txt_apsolute{
    display: flex;
    align-items: start;
    align-content: center;
    flex-direction: column;
    line-height: 30px;
    z-index: 1;
    text-align: left;
    @media screen and (min-width: 601px){
      max-width: 250px;
      position: absolute;
      top: 45%;
      gap: 150px;
      left: 0px;

      &._right{
        right: 0px;
        left:auto;
      }
    }
  }
  .fs-18{
    font-size: 17px;
    color:#f4f4f4;
  }

  .formContac{
    background: white;
    z-index: 1;
    position: relative;
    display: block;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    border-radius: 40px;
    text-align: center;
    @media screen and (min-width: 601px){
      padding: 40px;
    }
    @media screen and (max-width: 600px){
      padding: 20px;
    }
    .logo{
      max-width: 100%;
    }
  }
  .ft-primary{
    color: white;
    font-weight: 800;
  }
  .img_cont{
    width: 100%;
    z-index: 1;
    img{
      margin: 10px auto;
      @media screen and (min-width: 601px){
        max-height: 80vh;
      }
      @media screen and (max-width: 600px){
        max-height: 60vh;
      }
    }
  }
  .max-w-5xl{
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .logo {
    @media screen and (max-width: 600px) {
      width: 140px;
    }
  }
  .login_btn{
    z-index: 999;
    min-width: 110px;
    //position: absolute;
    //z-index: 2;
    //width: 110px;
    //font-size: 14px;
    //right: 14%;
    //@media screen and (min-width: 601px){
    //  top:2.5%;
    //}
    @media screen and (max-width: 600px){
      font-size: 12px;
      min-width: 95px;
    }
  }
  .register_btn {
    position: absolute;
    z-index: 2;
    width: 110px;
    font-size: 14px;
    right:5%;

    @media screen and (min-width: 601px){
      top:2.5%;
    }
    @media screen and (max-width: 600px){
      top: 16px;
    }
  }
  footer{
    background: transparent;
    z-index: 2;
    color: white;
    position: absolute;
    bottom: 10px;
    border-top:none;
  }
</style>
